@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";

//root

:root {
    --color-default:#004f83;
    --color-second:#0067ac;
    --color-white:#fff;
    --color-body:#e4e9f7;
    --color-light:#e0e0e0;
}

html, body { 
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

*:not(i) { 
    font-family: "Asap", sans-serif!important;
}

*::-webkit-scrollbar
{
	width: 8px;
	background-color: #F3F4F6;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #D9D9D9;
}

.cdk-overlay-pane { 
    width: 700px!important;
}

div.mat-mdc-autocomplete-panel { 
    padding: 0!important;
}

.card-header { 
    border-radius: 0.5rem 0.5rem 0 0!important;
}

.card {
    border-radius: 0.5rem!important;
}

.btn-primary { 
    background-color: #9747FF!important;
}

.ng-select .ng-clear-wrapper { 
    z-index: 2;
}

//end root

aside#sidenav-main+.main-content { 
    margin-left: 5rem;
    margin-top: 54px;
    transition: all .5s ease;
}

aside#sidenav-main+main.main-content.expand { 
    margin-left: 17.125rem;
}

#topbar { 
    transition: all .5s ease;
    box-shadow: 0px 0px 8px 0px rgba(132, 127, 123, 0.25);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 8;
}
#topbar.expand { 
    padding-left: 21.125rem;
}
//marca d'água ckeditor
.ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by a {
    display: none!important;
}

.project-code { 
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #847F7B
}

.project-name { 
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #D92D27
}

table .project-progress { 
    width: 210px!important;
    height: 8px!important;
}


.deadline { 
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.project-card {
    .project-progress {
        width: 100%;
     }
}

.progress-info * { 
    color: #847F7B;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.border-radius-4 { 
    border-radius: 4px!important;
}

.border-radius-8 { 
    border-radius: 8px!important;
}

#topbar { 
    padding-left: 8.25rem;
    #search { 
        width: 297px;
        height: 35px;
        * { 
            background: #FBF6F2;
            box-shadow: none!important;
        }
        .input-group-text{
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
        }
        input { 
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            &:focus { 
                border: 1px solid #d2d6da!important;
                border-left: 0!important;
            }
        }
    }
}

.bg-lightred { 
    background: #FBF6F2;
}

.bg-top-page {
    background-image: linear-gradient(to bottom right, #22bdb7, #44e794);
}

.box-atuacao {
    .item {
        padding: 10px 20px 20px;
        margin: 10px 0;
        border-bottom: 1px solid #e9ecef;

        &:last-child {
            border: 0;
        }
    }
}

.page-item.active {
    a {
        color: #fff;
    }
}

label,
.form-label {
    color: #677489;
}

.pointer {
    cursor: pointer;
}

.swal2-container {
    z-index: 9999 !important;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
    color: #fff !important;
}

.card {
    &.disabled {
        opacity: 0.7;
    }
}

ng-multiselect-dropdown > div > div.dropdown-list { 
    padding-top: 0!important
}

.multiselect-item-checkbox { 
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}

.multiselect-dropdown .dropdown-btn {
    min-height: 44px;
    border-radius: 0 !important;
    border: 0 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    background-color: #ffffff !important;
    display: flex !important;
    align-items: center;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    background: #5f5f5f !important;
    border: 0!important;
    padding: 0 8px !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    margin: 4px !important;
    max-width: min-content!important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    top: 12px !important;
    padding: 6px 8px !important;
    height: 26px !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
    background: #5f5f5f !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
    border: 2px solid #5f5f5f !important;
    border-radius: 4px !important;
}

.multiselect-item-checkbox input[type=checkbox]+div {
    font-size: 14px;
}

.dropdown-list .filter-textbox input {
    font-size: 14px;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect--active .dropdown-multiselect__caret {
    transform: rotateZ(180deg);
    top: 4px !important;
}

li.no-data {
    * {
        font-size: 14px;
    }
}

.text-version {
    .swal2-html-container {
        text-align: left !important;
        font-size: 14px !important;

        p {
            font-size: 14px !important;
        }
    }

    .swal2-title {
        font-size: 20px !important;
    }
}


.box-autocomplete {
    position: relative;

    .items {
        background: #fff;
        position: absolute;
        padding: 20px 0;
        z-index: 9;
        width: 100%;
        border-radius: 10px;
        max-height: 250px;
        overflow-y: auto;

        div {
            font-size: 14px;
            margin-bottom: 4px;
            cursor: pointer;
            padding: 5px 25px;

            &:hover {
                background-color: #e9ecef;
            }
        }
    }
}

.navbar-main {
    padding-top: 20px;
}

#sidenav-main { 
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
}

#sidenav-collapse-main { 
    .nav-link { 
        color: #fff;
    }
}

.navbar-vertical.navbar-expand-xs {
    z-index: 5 !important;
    background-image: linear-gradient(180deg, #BA2D22, #54140F);

    .nav-link.active { 
        background: #e54240!important;
    }

    .nav-link-text, .nav-item .nav-link { 
        color: #fff;
    }
}

.destaque-status {
    .card {
        border: solid 3px transparent;

        &.empty {
            background: rgba(0, 0, 0, 0.12);
            min-height: 78px;

            .card-body {
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    margin: 0;
                    color: #fff;
                    font-size: 12px;
                }
            }
        }

        .icon-shape {
            width: 52px;
            height: 52px;
            background-image: linear-gradient(to left, rgba(0, 0, 0, 0.1), transparent) !important;
        }

        &.actived {
            border: solid 3px orange;
        }

        .btn {
            position: absolute;
            right: 4px;
            top: 0;
            padding: 2px;
            box-shadow: none;
            font-size: 10px;
            color: #ddd;

            &:hover {
                color: #777;
            }
        }
    }
}


.kanban-container {
    border-top: 2px solid #DEDEDE;
    .row {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        padding: 0 0 30px;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .card {
        cursor: pointer;
    }

    .item {
        border-radius: 15px;
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        border: 0;

        h6 {
            // font-size: 12px !important;
            text-wrap: wrap;
        }

        &:hover {
            cursor: move;
        }
    }

    .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    .cdk-drag-placeholder {
        opacity: 0;
    }

    .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .item-drop {
        min-height: 60px;
    }
}

.navbar-vertical.navbar-expand-xs .navbar-collapse {
    height: auto !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 41px;
}

.ng-select .ng-select-container {
    border-radius: 0.5rem;
}

.sidenav-header {
    height: 6rem;
}

.navbar-vertical .navbar-brand>img,
.navbar-vertical .navbar-brand-img {
    max-width: 100%;
    max-height: 52px;
}

.kanban-title {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.kanban-card {
    box-shadow: none!important;
    border: 1px solid #EEE9E5!important;
    margin-bottom: 1rem;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    .tag-demand {
        position: absolute;
        top: -8px;
        left: 13px;
    }
}

.input-field-value {
    padding: 0.35rem 0.75rem;
    border-color: #fff;
}

.input-active {
    border-color: #d2d6da;
}

.input-field-value:disabled {
    background-color: #fff;
}

.blockbuilder-branding {
    display: none !important;
}

.bg-gradient-primary {
    background-image: linear-gradient(310deg, #15BDE5 0%, #72DD1D 100%) !important;
}

.bg-gradient-red {
    background-image: linear-gradient(0deg, #15bde500 0%, #E6272C 100%) !important;
}

.cal-month-view .cal-day-badge {
    background-color: #55cc8c;
    color: #fff;
}

.cal-month-view .cal-event-title {
    cursor: pointer;
    font-size: 14px;
}

.cal-month-view .cal-day-number {
    font-size: 16px;
    font-weight: bold;
    opacity: 0.5;
    margin-top: 15px;
    margin-right: 15px;
    float: right;
    margin-bottom: 10px;
}

.cal-week-view {
    .cal-time-events {
        display: none;
    }
}

.qrcode {
    img {
        max-height: 300px;
        max-width: 100%;
    }
}

.box-qr {
    .fa-qrcode {
        font-size: 260px;
        color: #e9ecef;
    }
}

.bg-gray-100 {
    background-color: #f8f9fa !important;
    min-height: 100vh;
}

span.cal-event-title.ng-star-inserted {
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 0px 2px #040404b0;
}

.cal-week-view .cal-all-day-events .cal-event {
    padding: 0 20px;
    margin-left: 2px;
    margin-right: 2px;
    height: 28px;
    line-height: 28px;
}

ngb-modal-window.d-block.modal.fade.show {
    z-index: 9999;
}

.modal-demand-form {
    border-radius: calc(0.75rem - 1px)!important;

    .modal-header {
        background: #222836;
        text-transform: uppercase;
        min-height: 64px;

        .tag-demand  {
            font-size: 40px;
            margin-top: -40px;
        }

        .modal-title {
            font-size: 20px;

            .demand_code {
                color: #FFFFFF80;
            }
        }

        img.flag {
            margin-top: -40px;
            margin-left: 30px;
            width: 35px;
        }

        .badge {
            border-radius: 20px;

            &.work {
                background: #11C223;
            }

            &.strategy {
                background: #343f5a;
            }

            &.approval {
                background: #00c1aa;
            }

            &.finished {
                background: #ff8313;
            }

            &.canceled {
                background: #e22820;
            }

            &.stopped {
                background: #e3ab00;
            }
        }
    }

    .requester {
        display: flex;
        align-items: center;
        color: #4a4a4a;

        img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
        }
    }

    .modal-body,
    container {
        background: #fff !important;
    }
}

.btn-action {
    width: 270px;
    height: 45px;
    padding: 10px 15px 10px 15px;
    border-radius: 8px;
    border: 0;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    &.btn-rating { 
        background: #00C1AA;
    }
    &.btn-finish { 
        background: #9747FF;
    }
    &.btn-send { 
        background: #9747FF;
        width: 167px;
        height: 40px;
    }
    &.btn-add-demand { 
        background: #F9A41C;
        height: 40px;
    }
    &.btn-reject { 
        background: #BD1712;
        width: 167px;
        height: 40px;
    }
    &.btn-filter {
        background: #F9A41C;
        width: auto;
        height: 40px;
    }
}

.demand-list {
    .card.item {
        border-radius: 8px!important;
        box-shadow: none!important;
        padding: 12px 20px;
        border: 1px solid #EEE9E5;
        margin-bottom: 15px;
        position: relative;

        &.active {
            background: #4F4B48;

            .project-name, .project-code, .demand-name, .deadline, .deadline *, .comments, .comments * {
                color: #fff;
            }
        }

        .project-code, .demand-name {
            font-size: 15px;
        }

        .project-code {
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            font-size: 15px;
            color: #2D2926;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .tag-demand {
            position: absolute;
            right: 20px;
            top: -10px;
        }

                
        .ng-select .ng-select-container {
            border-radius: 4px;
        }
    }
}

.preview-demand {
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    .modal-demand-form {
            min-height: 100%;
        & > .modal-body, & > .modal-header { 
            padding: 0 20px;
        }
        & > .modal-header {
            background: #847F7B;
            .tag-demand {
                font-size: 32px;
                margin-top: 0;
            }
        }
        // hr:nth-child(1) {
        //     margin: 0!important;
        // }
    }


    .info-text {
        font-size: 20px;
        color: #D9D9D9;
        font-weight: bolder;
        border: 4px #D9D9D9 dashed;
        text-align: center;
        padding: 60px;
        height: calc(100% - 120px);
    }
}

.search-demand {
    label {
        color: #fff;
    }

    background: #2D2926;
    border-radius: 0 0 8px 8px;

    button { 
        border-radius: 4px!important;
    }

    .ng-select-container {
        border-radius: 4px!important;
    }

    .btn-outline-danger {
        background-color: #BD1712 !important;
        color: #fff;
        margin: 0;

        &:hover {
            background-color: #BD1712 !important;
            color: #fff;
        }
    }

    .btn-outline-primary {
        background-color: #5E72E4;
        color: #fff;
        margin: 0;

        &:hover {
            background-color: #5E72E4;
            color: #fff;
        }
    }

    hr {
        margin: 20px 0;
        display: block;
        background: #fff;
    }
}

.search-demand *:not(.emoji-selector):not(.emoji-selector *),
.search-demand *::placeholder,
.search-demand .btn-emoji-text {
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
}

.search-demand { 
    *::placeholder, .btn-emoji-text, input, ng-select * {
        color: gray!important;
    }
}

.create-demand-card-requester {
    .asset {
        max-width: 35%;
    }
}

.box-comment {
    width: 100%;
    position: sticky;
    background: #fff !important;
    bottom: 0;
    z-index: 9;
    .btn-border {
        border-bottom: solid 2px #9747FF;
    }
    .btn-comment {
        margin: 0;
        background: #9747FF;
        color: #fff;
        border-radius: 25px 25px 0px 0px;
        min-width: 200px;
        height: min-content;
    }

    textarea { 
        min-height: 170px;
    }
    
    textarea.form-control.is-invalid {
        padding-right: unset;
        background-position: 100% calc(100% - 15px);
    }

    button[type=submit] {
        cursor: pointer;
        display: flex;
        align-items: flex-end;
        padding-bottom: 15px;
    }
}

app-demands-form {
    position: relative;

    textarea#requester-description {
    }

    input[type=text], 
    input[type=email] {
        border-radius: 0!important;
        border: 0!important;
        font-size: 15px!important;
        font-weight: 400!important;

    }
    input[readonly]{ 
    background: #f3f3f3!important;
    }

    label.form-label {
        font-size: 16px;
        font-weight: 500;
        color: #4a4a4a;
    }

    .pill-menu {
        button.nav-link { 
            color: #4a4a4a;
            //   border-radius: 0;
            border-radius: 0!important;
            &.active {
                color: #5e72e4;
                border-bottom: 2px solid #5e72e4;
            }
        }
    }

    #pills-briefing { 
        .ck-editor__editable {
            min-height: 300px;
        }
    }

    .dropdown-list {
        li.no-data {
            h5 {
            font-size: 14px;
            }
        }
    }

    .emoji {
    font-family: 'Noto Color Emoji', sans-serif!important;
    cursor: pointer;
    color: #B4B4B4;
    font-size: 26px;;
    background: white;
    border: 0;
    box-shadow: none!important;
    height: 40px;
    }

    .multiselect-dropdown {
        .dropdown-btn {
            overflow: hidden;
            background: #f3f3f3!important;
        }
        .selected-item {
            white-space: nowrap;
        }
    }

    .form-label {
        font-size: 14px;
    }

    main>.container-fluid {
        background: #fff !important;
    }

    #pills-activities {
        ng-select {
            width: 375px;
        }

        .demand-events-list li { 
            border: 1px solid lightgray;
            border-radius: 13px
        }

        .ng-select-container {
            background: #F6F9FC !important;
            color: #b4b4b4 !important;
            height: 45px !important;
            border: 0;
            padding-left: 22px;
            font-style: italic;
        }

        input { 
            color: gray;
            font-style: italic;
            box-shadow: none;
        }

        .ng-dropdown-panel.ng-select-bottom,
        .ng-dropdown-panel.ng-select-top {
            border: 0;
            margin-top: 2px;
        }

        .ng-dropdown-panel .scroll-host>div {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        .ng-option {
            height: 45px;
            font-size: 14px;
            line-height: 30px;
            border-radius: 5px;
            background: #fff !important;
            color: #141414;
            font-weight: normal;
            font-style: normal;
            transition: .2s;
            // &:hover {
            //     font-weight: 800;
            //     border-left: 4px solid #e22820;
            //     background: #d5dde9;
            //     transition: .2s;
            // }
        }

        .form-group.tel {
            ng-select {
                width: 67px;
                * {
                    font-family: 'Noto Color Emoji', sans-serif!important;
                }
            }

            .ng-select-container {
                padding: 0 !important;
            }

            .ng-value {
                margin-top: 6px;
                background: transparent !important;
                box-shadow: none !important;
            }

            ng-option {
                &.hover {
                    background: #eef2f8;
                    color: dimgray;
                    font-weight: normal;
                    transition: 0;
                    border: 0;
                }
            }
        }

        .form-group.date {
            img {
                filter: brightness(0.5);
            }

            background: #F6F9FC;
            display: flex;
            gap: 20px;
            border-radius: 0.5rem
        }
    }
}

.kanban-container {
    .card.status { 
        border-radius: 8px;
        background: #F5F5F5;
        box-shadow: 0px -2px 8px 0px rgba(132, 127, 123, 0.25);
        max-height: 708px;
        height: 708px;
        overflow-y: scroll;
    }
    
    .cdk-drop-list.item-drop, .cdk-drag{
        pointer-events: none;
    }
}

.nav.nav-tabs {
    margin-bottom: 15px;

    a {
        font-weight: bold;
        font-size: 14px;
    }
}

#solicitation {
    min-height: 100vh;

    .title {
        font-size: 40px;
    }

    * {
        font-family: 'Lato', sans-serif
    }

    & {
        background: url('assets/solicitations/bg-solicitation.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }

    form {
        h2 {
            color: #092c5a;
            font-weight: 900;
        }

        p.observation {
            color: #85c750;
        }

        label.form-label {
            color: #092c5a;
            font-weight: 900;
        }

        hr {
            border-top: 1px solid;
        }

        .form-check:not(.form-switch) .form-check-input[type="radio"] {
            padding: 10px;
            cursor: pointer;
        }

        .form-check-input[type="radio"]:after {
            z-index: 100;
            content: "";
            position: absolute;
            background-color: transparent !important;
            width: 100% !important;
            height: 100% !important;
            border: 1px solid white;
            top: 0;
            left: 0;
            border-radius: 50%;
            opacity: 1 !important;
        }

        .form-check:not(.form-switch) .form-check-input[type="radio"]:checked:after {
            opacity: 0;
        }

        .form-check-input:checked[type="radio"] {
            background: #85c750;
            border: 1px solid #707070;
            outline: 1px solid #fff;
        }
    }

    .insights {
        .card {
            cursor: pointer;

            &.selected {
                .card-header {
                    background: #85C750;
                }

                .card-body {
                    background: #85c7502e;
                }
            }

            textarea {
                border: 0;
                background: none;
                box-shadow: none !important;
                height: 100%;
                outline: none;
                resize: none;
            }
        }

        .card-header {
            background: #484848;
        }

        .card-body {
            background: #f3f3f3;
            color: #828282;
            border-radius: 0px 0px 10px 10px;
        }
    }

    .btn-green {
        position: relative;
        box-shadow: none !important;
        background: #85C750 0% 0% no-repeat padding-box;
        border-radius: 10px;
        padding: 12px 35px;
        border: 0;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;

        i {
            position: absolute;
            left: 0;
            top: 0;
            /* background: red; */
            color: rebeccapurple;
            top: 0;
            right: 50%;
            transform: translate(50%, -50%);
            font-size: 30px;
        }
    }

    .btn-blue {
        position: relative;
        box-shadow: none !important;
        background: #1752A2 0% 0% no-repeat padding-box;
        border-radius: 10px;
        padding: 12px 35px;
        border: 0;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;

        i {
            position: absolute;
            left: 0;
            top: 0;
            /* background: red; */
            color: rebeccapurple;
            top: 0;
            right: 50%;
            transform: translate(50%, -50%);
            font-size: 30px;
        }
    }

    .btn-gray {
        position: relative;
        box-shadow: none !important;
        background: #828282;
        border-radius: 10px;
        border: 0;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;

        i {
            position: absolute;
            left: 0;
            top: 0;
            /* background: red; */
            color: rebeccapurple;
            top: 0;
            right: 50%;
            transform: translate(50%, -50%);
            font-size: 30px;
        }
    }

    .text-darkblue {
        color: #092c5a;
    }

    .ai-container {
        z-index: 0;
        position: relative;

        .ai-header {
            background-image: url('assets/solicitations/bg-solicitation-ia.png');
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .ai-card {
            box-shadow: 0px 3px 6px #00000029;
        }
    }

    .ck.ck-editor__main>.ck-editor__editable {
        border: 0 !important;
        background: transparent !important;
        cursor: auto;
        box-shadow: none;
    }

    .ck-reset_all :not(.ck-reset_all-excluded *),
    .ck.ck-reset_all {
        display: none
    }

}

[class^="ngx-file-drop__content"] {
    height: 100% !important;
    flex-direction: column;
}

[class^="ngx-file-drop__drop-zone"] {
    height: 151px !important;
    margin: auto !important;
    border: 1px solid lightgray !important;
    border-radius: 10px !important;

    * {
        color: #D2D2D2 !important
    }
}

.disabled .dropdown-btn {
    filter: contrast(0.8)
}

app-screening {
    .card-header {
        color: #707385;
        font-size: 16px;
    }

    .status-screening {
        height: 100%;

        .card {
            height: 100%;
        }

        .item {
            .count {
                border-radius: 0 20px 0 20px;
                height: 50px;
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto 5px;
                background: #008BC7;
                color: #fff;
                font-weight: bold;

                &.today {
                    background: #FA7700;
                }
            }

            span {
                display: block;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        height: 40px!important;
        border-radius: 7px!important;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
        height: auto;
        padding: 0!important;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix { 
        padding: 5px 0 0 0;
    }
}

.home-dashboard { 
}

.table-timesheet { 
    * { 
        color: #4F4B48
    }
}

.table-agendas {
    .event-name { 
        font-family: Asap;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #4F4B48;
    }
    .btn {
        padding: 5px;
        margin: 0;
        box-shadow: none;
        color: #5E72E4;
    }
    .look-all { 
        font-family: Asap;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #9747FF;
        border: 0!important;
        box-shadow: none!important;
        outline: none!important;
        background: transparent;
    }

    #event-list li:last-child { 
        border: 0!important
    }
}

.table-tarefas {
    small { 
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #4F4B48;
    }
}

.navbar {
    h6 {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.24px;
        text-transform: uppercase;
    }
}

.flag-count {
    text-align: center;

    .count {
        border-radius: 8px;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #008BC7;
        color: #fff;
        font-weight: bold;

        &.today {
            background: #F57A0E;
        }

        &.all { 
            background: #4F00E9;
        }

        &.work {
            background: #11C223;
        }
    }

    span {
        display: block;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-wrap: nowrap;
        color: #4F4B48;
    }
}

.table-filter {
    .filters { 
        position: relative;
        .box-filters {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 10;
            background: #000;
            padding: 20px;
            border-radius: 0.5rem;
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 325px;

            span.title { 
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                color: #fff;
            }

            label { 
                color: #fff;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            select, option, input { 
                background: #fff;
                color: gray;
            }
            
        }
    }

    .form-control, .btn-search, .btn-filters, .btn-view-toggle {
        border: 0;
        color: #847F7B;
        background: #F7F1ED;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        height: 40px;
        width: 40px;
    }

    .btn-filters:focus { 
        background: #2D2926;
        img { 
            filter: brightness(10) contrast(10);
        }
    }
    
    .btn-filters-square {
        width: 40px;
    }


    .btn-filters { 
        &.show { 
            background: #000;
            color: #fff;
        }
    }

    .search input { 
        width: 354px;
        border: 0!important;
        box-shadow: none!important;
        outline: none!important;
    }
}

.badge-status {
    border-radius: 20px;
    padding: 10px 30px;
    display: flex;
}

.btn-link {
    background: #F4F5FA;
}

.box-status-container {
    position: relative;
    z-index: 7;

    .card {
        background: transparent;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        border-width: 0 0 7px 0;

        .icon {
            height: 50px;
            width: 50px;
            background: #EAF9FF;
            border-radius: 8px;
            z-index: 99;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // transform: skewX(-10deg);
                max-height: 27px;
            }
        }

        .card-header {
            margin-left: 10px;
            color: #fff;
            font-size: 12px;
            font-weight: bolder;
            border-radius: 0;
            position: relative;
            z-index: 9;

            span {
                display: block;
            }

            &.work {
                background: #11C223;
            }

            &.strategy {
                background: #343f5a;
            }

            &.approval {
                background: #00c1aa;
            }

            &.finished {
                background: #ff8313;
            }

            &.canceled {
                background: #e22820;
            }

            &.stopped {
                background: #e3ab00;
            }
        }

        .card-body {
            background: #fff;

            h5 {
                font-size: 34px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.34px;
                text-transform: uppercase;
                text-align: left;
                line-height: 35px;
            }
        }
    }
}

.demand {

    .date { 
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    
    .deadline { 
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .title {
        margin: 0;
        span.demand-name {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #2D2926;
        }

        span.project-code, span.project-name {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #847F7B;
        }

        span.project-code { 
            font-weight: 600;
        }
    }

    button {
        border-radius: 20px;
    }

    .badge {
        height: max-content;
        border-radius: 20px!important;

        &.work {
            background: #11C223;
        }

        &.strategy {
            background: #343f5a;
        }

        &.approval {
            background: #00c1aa;
        }

        &.finished {
            background: #ff8313;
        }

        &.canceled {
            background: #e22820;
        }

        &.stopped {
            background: #e3ab00;
        }
    }
}

.demands-status {
    .demand { 
        border: 2px solid #F0F0F0;
        padding: 20px !important;
    }
    h5 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #4F4B48;
    }

    #view-all {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #9747FF;
        cursor: pointer;
    }

    .badge {
        line-height: inherit;
    }
}

.requester-demand-form {

    & > .card-header { 
        background-image: linear-gradient(180deg, #000000, #4A4A4A);
        border-top-left-radius: 11px;
        border-top-right-radius: 11px;

        & span:nth-child(1){ 
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
        }
        & span:nth-child(2){ 
            font-size: 45px;
            font-weight: 700;
            line-height: 45px;
            color: #FD0202;
        }
    }

    #requester-info { 
        input { 
            background: #EEE7E7!important;
            color: #666666!important;
        }
    }
}

.requester-demand-form, .modal-demand-form { 
    
    .box-title {
        color: #4A4A4A;
        font-weight: 700;
        font-size: 20px;
    }

    .multiselect-dropdown {
        .dropdown-btn {
            overflow: hidden;
        }
        .selected-item {
            white-space: nowrap;
        }
    }

    
    .ng-select-container,
    input[type="text"],
    input[type="email"],
    input[type="date"] {
        background: #fff;
        color: #b4b4b4 !important;
        min-height: 45px !important;
        border: 0;
        padding-left: 22px!important;
        font-style: italic;
    }

    .ng-dropdown-panel.ng-select-bottom,
    .ng-dropdown-panel.ng-select-top {
        border: 0;
        margin-top: 2px;
    }

    .ng-dropdown-panel .scroll-host>div {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .ng-option {
        height: 45px;
        font-size: 14px;
        line-height: 30px;
        // border-radius: 5px;
        color: #141414;
        font-weight: normal;
        font-style: normal;
        transition: .2s;
            &:hover, &.ng-option-selected{
                font-weight: 800;
                background: #CCC7C3!important;
                border-radius: 0;
                transition: .2s;
            }
    }
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left,
    form.card .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{
        border-color: #4a4a4a;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
        top: auto!important;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value { 
        box-shadow: 1px 1px 3px lightgray; 
        background:#4a4a4a;
        color: #fff;
    }

    .ng-select .ng-arrow-wrapper .ng-arrow { 
        z-index: 10!important;
    }

    .ng-select-single .ng-value {
        box-shadow: none!important;
        background: transparent!important;
        z-index: 5!important;
        font-size: 14px;
        font-style: italic;
    }

    .form-group.tel{
        ng-select {
            width: 67px;
            * {
                font-family: 'Noto Color Emoji', sans-serif!important;
                font-size: 20px;
            }
        }

        .ng-select-container {
            padding: 0 !important;
        }

        .ng-value {
            background: transparent !important;
            box-shadow: none !important;
        }
    }

    label {
        color: #4a4a4a;
        font-size: 16px;
    }

    .form-check:not(.form-switch) .form-check-input[type="checkbox"], .form-check:not(.form-switch) .form-check-input[type="radio"]{
        width: 16px;
        height: 16px;
        border-radius: 4px;    
    }

    .form-check:not(.form-switch) .form-check-input[type="radio"]{
        padding: 0;
    }

    .ng-input>input{
        min-height: 37px!important;
    }

    .form-check:not(.form-switch) .form-check-input[type="radio"]:after {
        transition: opacity 0.25s ease-in-out;
        font-family: "FontAwesome";
        content: "\f00c";
        width: 100%;
        height: 100%;
        color: #fff;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.67rem;
        background: transparent;
    }

    input, .ng-placeholder {
        font-size: 14px;
    }

    .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
        box-shadow: none!important;
    }

    .ng-placeholder {
        background: #fff ;
        color: #b4b4b4!important;
        z-index: 10!important;
        // margin-top: 7px;
    }

    .form-check-input:checked[type="checkbox"], .form-check-input:checked[type="radio"] {
        background: #000!important;
    }
}

.criticality { 
    .low, ._1 { 
        fill: #008BC7;
        color: #008BC7;
    }
    .medium, ._2 { 
        fill: #11C223;
        color: #11C223;
    }
    .high, ._3 { 
        fill: #FDAE02;
        color: #FDAE02;
    }
    .urgent, ._4 { 
        fill: #FD0202;
        color: #FD0202;
    }
}



.box-alert {
    background: #ffd600;
    position: relative;

    .icon-alert {
        position: absolute;
        top: -20px;
        right: 30px;
        width: 40px;
        height: 40px;
    }

    p.message,
    a {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000;
    }

    a {
        color: #e22820;
        text-decoration: underline;
    }
}

#demandSolicitationForm {
    background: rgba(0, 0, 0, 0.75);

    .modal-dialog {
        max-width: 800px
    }
}

app-file-upload {
    ngx-file-drop p {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.11px;
        text-align: center;
        color: #4a4a4a !important;
    }
}

.login-page {
    h3 {
        font-size: 20px;
    }
}

.chat {
    * {
        font-size: 14px !important;
    }

    h3 {
        color: #4a4a4a
    }

    border-radius: 10px;

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }

    .card {
        padding: 14px;
        color: #4a4a4a;

        .card-header {
            span.timestamp {
                color: #b4b4b4;
            }
        }

        &.comment {
            background: #f4f5fa;
            border-radius: 0 20px 20px 20px;
            max-width: 75%;
        }

        &.user-comment {
            background: #efe3e2;
            border-radius: 20px 0 20px 20px;
            max-width: 75%;
        }
    }

    textarea {
        padding-top: 12px;
        padding-bottom: 10px;
        resize: none;
        overflow: hidden;

        &::placeholder {
            color: #ebebeb
        }
    }

    button[type=submit] {
        cursor: pointer;
    }

    .chat-box {
        min-height: 100px;
        max-height: 350px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 12px;
            border-radius: 10px;
            padding: 0;
        }

        &::-webkit-scrollbar-track {
            background: #f3f4f6;
            border-radius: 10px;
            width: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background: #d9d9d9;
            border-radius: 10px;
            border: 3px solid #f1f1f1;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }
    }
}

.custom-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.custom-input {
    border-color: #ccc !important;
    color: #333 !important;
    width: 48px !important;
    height: 53px !important;
    font-size: 1.2rem !important;
    text-align: center !important;
    border: 1px solid #c4c4c4 !important;
    border-radius: .5rem !important;
    outline: none !important;
}

.custom-input-filled,
.custom-input:focus {
    border: 1px solid #007bff !important;
}

.custom-input-disabled {
    background-color: #f5f5f5 !important;
    color: #999 !important;
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.custom-input-error {
    border: 1px solid #fe0000 !important;
}

.otp-invalid {

    .custom-input-filled,
    .custom-input:focus {
        border: 1px solid #fe0000 !important;
    }
}

.custom-modal {

    &::-webkit-scrollbar
    {
        width: 8px;
        background-color: #F3F4F6;
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background-color: #D9D9D9;
    }

    .modal-dialog {
        max-width: 1500px;
        width: 90vw;
    }
}
.btn-add {
    background: #F4F5FA;
    color: #5E72E4;
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    border: 0;
    border-radius: 10px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.attachment-title { 
    .badge {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        padding: 0;
        background: #E22820 !important;
    }
}
.attachment-list {
    .badge {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        padding: 0;
        background: #E22820 !important;
    }

    .list-group-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #f0f0f0;
        padding: 15px;
        border-radius: 8px;
        background-color: #f9f9f9;
        margin-bottom: 10px;
    }

    .list-group-item img {
        width: 40px;
        height: 40px;
        object-fit: cover;
    }

    .file-info {
        flex-grow: 1;
        margin-left: 15px;
    }

    .file-info h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: #5e72e4;

    }

    .file-info p {
        margin: 0;
        font-size: 12px;
        color: #4A4A4A;
    }

    .upload-hint {
        text-align: center;
        color: #666;
        margin-top: 20px;
    }

    .upload-hint a {
        color: #0d6efd;
        text-decoration: underline;
    }
}

.avatar img{
    object-fit: cover;
}

.tasks {
    input[type=radio] {
        width: 25px;
        height: 25px;
        border: 2px solid #D9D9D9;
    }

    label {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #4a4a4a !important;
        text-transform: uppercase !important;
    }


    .dropdown.disabled {
        pointer-events: none;
        opacity: 0.5;
    }


    .calendar-icon {
        filter: brightness(0.4);
    }

    .task-row {

        display: flex;
        flex-direction: row;
        margin-bottom: 4px;
        border-radius: 10px!important;
        
        &.disabled {
            opacity: 0.7;
            pointer-events: none;
        }

        &:hover {
         
            .pencil-btn {
                padding: 0px 10px;

                i {
                    display: block;
                }
            }
        }

        .pencil-btn {
            border-radius: 0px 10px 10px 0px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 5px;
            box-shadow: none;
            border: 0;
            background: transparent;

            i {
                display: none;
                color: white;
            }
        }

        .card-body {
            background: white;
            border-radius: 10px;

            > div {

                border-radius: 10px;
            }
        }

        * {
            color: #4a4a4a;
        }

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .status-indicator {
            width: 10px;
            height: 100%;
            border-radius: 0 10px 10px 0;
        }

        .remove-task, .save-task {
            color: #fff;
            width: 23px;
            height: 23px;
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
        }
        .remove-task { 
            background: #FD0202;
        }
        .save-task { 
            background: #0077ff
        }
        .form-control {
            &.date {
                border: 0;
                border-radius: 0;
                background: transparent;
                box-shadow: none!important;
                &:focus {
                    border-bottom: solid 1px #ddd;
                }
            }
        }
    }

    .btn:disabled,
    .btn.disabled,
    fieldset:disabled .btn {
        color: #7f7f7f !important;
    }

    .box-users {
        position: relative;
        h5 {
            font-size: 14px;
            font-weight: bold;
            background: #ddd;
        }
        .list {
            position: absolute;
            top: 0;
            left: 0;
            width: 200px;
            height: 150px;
            overflow-y: auto;
            background: #fff;
            z-index: 99;
            border-radius: 6px;
            border: solid 1px #ddd;
            .item {
                &:hover {
                    background: #ddd;
                }
            }
        }
    }

}

.select-view {
    display: flex;

    hr {
        height: 2px;
        background: #F3F4F6;
        width: 100%;
        margin-right: 20px;
        opacity: 1;
    }

    .btn {
        font-size: 20px;
        padding: 0;
        height: 36px;
        width: 36px;
        border-radius: 5px;
        background: #F3F4F6;
        color: #A3A6B1;

        &.active {
            color: #fff;
            background: #707385;
        }
    }

}

.users {
    .box-users {
        .item {
            background: #F3F4F6;
            margin-bottom: 5px;
            border-radius: 10px;
            padding: 15px;
            display: flex;

            .group {
                font-weight: bold;
                color: #5E72E4;
            }

            .btn {
                font-size: 20px;
                padding: 0;
                height: 36px;
                width: 36px;
                border-radius: 5px;
                background: #F3F4F6;
                color: #A3A6B1;
            }

            &.new {
                background: #fff;
                border: solid 1px #F3F4F6;
                padding: 15px;

                .btn {
                    margin-bottom: 0;
                    box-shadow: none;
                    width: 100%;
                    font-size: 16px;
                    height: auto;
                    background: transparent;
                }
            }
        }
    }
}

.box-userbox-users {
    h4 {
        font-size: 18px;
        color: #707385;
        font-weight: bold;
    }

    .item {
        padding: 10px 0;

        .avatar {
            width: 100px;
            height: 100px;
            object-fit: cover;
        }

        h6 {
            font-weight: bold;
            font-size: 26px;
        }

        p {
            font-size: 14px;
            margin-top: -4px;
        }

        a {
            font-size: 14px;
        }
    }


    .box-form {
        background: #F3F4F6;
        border-radius: 10px;
        padding: 25px;

        p {
            font-size: 14px;
        }
    }
}

.emoji-selector {
    position: relative;

  .btn-emoji {
    font-family: 'Noto Color Emoji', sans-serif!important;
    cursor: pointer;
    color: #B4B4B4;
    font-size: 26px;;
    background: white;
    border: 0;
    box-shadow: none!important;
  }

  .btn-emoji-text {
    background: transparent;
    border: 0;
    box-shadow: none !important;
  }
  
  .emoji-list {
    border-radius: 5px;
    background: white;
    position: absolute;
    z-index: 100;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    width: 446px;
    max-height: 200px;
    overflow-y: auto;
    font-family: 'Noto Color Emoji', sans-serif!important;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px #00000026;

    &.top { 
        margin-top: -237px;
    }
    &.bottom { 
        margin-top: 12px;
    }

    .emoji-item {
      cursor: pointer;
      text-align: center;
      font-size: 26px;
      font-family: 'Noto Color Emoji', sans-serif!important;
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}

.file-style-1 {
    p, span { 
        color: #4a4a4a!important
    }
    
    .ngx-file-drop__drop-zone {
        height: 151px !important;
        margin: auto !important;
        border: 1px solid lightgray !important;
        border-radius: 10px !important;

        .ngx-file-drop__content {
            height: 100% !important;
            flex-direction: column;
        }
    }
}

.file-style-2 {
    
    .ngx-file-drop__drop-zone {
        height: 60px!important;
        background: #F4F5FA;
        border: 0!important;
        border-radius: 0px 0px 10px 10px!important;
    
        .ngx-file-drop__content {
            height: 100%!important;
        }
        img {
        width: 22px!important;
        margin-right: 6px
        }
    }
    

    p, span { 
        color: #707385!important;
        font-size: 13px;
        font-weight: normal!important;
    }
    span {
        text-decoration: underline;
    }
}


.file-style-3 {
    .ngx-file-drop__drop-zone {
        border: 0!important;
    }

    .avatar-photopicker-overlay {
        position: absolute;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        top: 0;
        left: 0;
        background: rgba(84, 101, 111, .8);
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .3s;
        &:hover { 
            opacity: 1!important;
            transition: .3s;
        }
        
    }
}



//start angular material

ngb-modal-window.d-block.modal.fade.show {
    z-index: 10
}

.modal-backdrop {
    z-index: 8!important;
    --bs-backdrop-zindex: 8!important;
}

.mat-button-focus-overlay {
    background: transparent;
}

//end angular material

.avatar {
    object-fit: cover;
}



.mat-option { 
    max-height: 41px!important;
    * { 
        font-size: 13px;
        font-weight: normal!important;
    }
}

.search-themes {

    mat-form-field {
        * {
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            color: #495057;
        }
        input, .mat-form-field-label { 
            padding-left: 16px!important;
            margin: 0!important;
        }
    }

    .cdk-overlay-pane { 
        min-width: 700px !important;
        width: 700px!important;
        max-width: 700px!important;
    }

    .avatar { 
        width: 34px;
        height: 34px;
    }

    .example-full-width {
        width: 100%;
    }
    .mat-form-field-appearance-legacy .mat-form-field-infix {
        padding: .4375em 0;
        background: #fff;
        border-radius: 0.5rem;
        border: 0;
    }
    .mat-form-field-appearance-legacy .mat-form-field-underline { 
        display: none;
    }

    .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label .ng-star-inserted {
        display: none;
    }
  }

  .mat-option-text{
    display: flex!important;
    gap: 7px!important;
    align-items: center;
}

app-screening-demands {
    .cdk-overlay-pane { 
        width: 700px!important;
      }
}

.emoji {
    font-family: 'Noto Color Emoji', sans-serif!important;
    cursor: pointer;
    color: #B4B4B4;
    font-size: 20px;;
    background: white;
    border: 0;
    box-shadow: none!important;
  }

  .bs-datepicker-container { 
    padding: 0!important;
  }

  
.modal-event-calendar .modal-dialog{ 
    max-width: 660px;
}

  .event-calendar { 

    position: absolute;
    top: 15px;
    right: 0;
    z-index: 100;
    border-radius: 13px;
    width: 100%;

    [class^='col'], div[class*='col'] { 
        position: relative;
        
    }
    
    label { 
      margin-bottom: 0;
      text-wrap: nowrap!important;
      font-size: 16px;
        font-weight: 500;
        color: #4a4a4a;
    }

    input:not([type=checkbox]),select { 
      width: min-content!important;
    }
    
    ngx-weekly [class^='col'], ngx-weekly div[class*=' col'] { 
      margin: 0!important;
    }
    
    ngx-weekly * { 
      margin-top: 5px;
    font-size: 13px
  }
    
    ngx-weekly { 
        .form-check-inline { 
        display: flex;
        align-items: center;
      }
      .form-check-label {
        margin-bottom: 0;
        pointer-events: none!important;
      }
    }

    ngx-yearly form,
    ngx-monthly form{ 
    margin-top: 20px!important;
    }

    ngx-repeat > form > div.row.align-items-center > div:nth-child(2) > input { 
        width: 60px!important
    }

    ngx-end > form > div > div.col-auto.d-flex.align-items-center > div > div > input { 
        width: 60px!important;
        margin-right: 12px
    }

    input[bsdatepicker] {
        max-width: 100px 
    }
    
    .col-auto { 
     padding: 0 8px 0 0!important
    }
      
    .form-group {
         margin-bottom: 0!important
    }
  }

  
    
  .theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after,
  .theme-green .bs-datepicker-head{ 
  background: #222836!important;
  }
  
  .theme-green .bs-datepicker-body table td.week span { 
  color: #222836!important
  }

  /* PROJECTS */

app-project-detail app-demand-table .card {
    box-shadow: none;
    padding: 0px;
}
  
.mat-mdc-option .mdc-list-item__primary-text { 
    * { 
        letter-spacing: 0!important;
    }
    margin: 0!important;
    width: 100%!important;

    small { 
        font-size: 11px!important;
    }
}

.tasks .dropdown-toggle { 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selected-item-container { 
    width: 100%;
  }
  
.selected-item { 
    width: inherit!important;
}

.field-filled-error { 
    color: #BD1712;
    margin-left: 7px;
    font-size: 11px;
}

.custom-modal-backdrop { 
    background: #00000045;
}

.modal-event-calendar { 
    background: #00000045;
}

.mdc-text-field--filled .mdc-line-ripple::after,.mdc-text-field--filled .mdc-line-ripple::before{ 
    display: none
  }
  
.mdc-text-field--filled:not(.mdc-text-field--disabled){ 
background: #fff!important;
}


.demand-name, .project-name { 
text-transform: uppercase!important;
}

.demand-events-list li:not(.new),
.demand-events-list li:not(.new) input,
.demand-events-list li:not(.new) button{ 
background: #f4f5fa!important;
}
  
.btn-blink {
    animation: blink 1s infinite;
    }

    @keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    }

@media (max-width: 1440px) { 
   
}



@media (max-width: 1660px) { 
    .preview-demand .tasks { 
        width: 100% !important;
        margin-top: 15px;
    }

    .preview-demand .pill-menu {
        width: 100% !important;
        margin-top: 25px;
    }

    .demand-content{ 
        // padding: 0 25px!important;
    }
}

@media (max-width: 1440px) {

    .box-init-demand {
        margin-top: 1rem!important;
    }

    #topbar.expand { 
        padding-left: 18.125rem!important;
    } 

    .navbar h6 { 
      font-size: 15px
    }
    
    .modal-demand-form .modal-header { 
      min-height: 52px
    }
    
    .preview-demand{ 
      height: 465px;
    }
    
    .modal-demand-form .modal-header .modal-title {
      font-size: 16px
    }
    
    .btn-action{
        font-size: 11px;
    }

    .kanban-title { 
      font-size: 12px!important;
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }
    
    .project-name,
    .project-code,
    .demand-name,
    .deadline { 
    font-size:   13px!important;
    }

    .demands-status {
        h5 { 
            font-size: 0.9rem!important;
        }
        .demand { 
            padding: 15px!important;
            
        }

        & > .card { 
            padding: 1rem 0 !important;
        }

        .card {
            .card-body { 
                padding: 0 15px!important;
            }
        }
    }

    .box-status-container {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;

        .card { 
            .card-body {
                padding: 10px 15px!important;
            }

            .icon { 
                width: 40px;
                height: 40px;

                img { 
                    max-height: 20px;
                }
            }
        }
    }

    .sidebar.open {
        width: 228px!important;
    }
    
    .logo_name {
    margin-left: -5px!important;
    }
    
    .sidebar .link_name{ 
    font-size: 12px!important;  
    }
    .sidebar li .link-icon img { 
    width: 15px!important;
    height: 15px!important;
    }
    
    .table *:not(.emoji, .badge-status) { 
        font-size: 13px!important
    }

    .badge-status {
        border-radius: 20px;
        padding: 9px 22px;
        font-size: 10px !important;
    }
    
    .table tbody td { 
    padding-top: 3px!important;
    padding-bottom: 3px!important;
    }

    .table .emoji {
    font-size: 27px!important;
    }
    
    aside#sidenav-main + .main-content { 
    margin-left: 4rem!important;
    }
    
    aside#sidenav-main + main.main-content.expand {
    margin-left: 14rem!important;
    }

    #sidenav-main { 
        max-width: 200px!important;
      }
      
      #sidenav-main * { 
      font-size: 12px!important;
      }
      
      .sidenav.fixed-start+.main-content { 
      
        margin-left: 12.5rem;
      }
      
      .status-group * { 
        font-size: 14px;
      }
      
      .status-group > .card-body { 
        padding: 20px!important;    
      }
      
      .preview-demand .modal-demand-form > .modal-body
      .preview-demand .modal-demand-form > .modal-header { 
        padding: 0 20px!important;
      }  
      
      .preview-demand .modal-body *{ 
        font-size: 14px!important;
      }
      
      .multiselect-dropdown *{ 
      font-size: 12px!important;
      }
      
      .chat-box * { 
        font-size: 12px!important;
      }

    .tasks input[type=date] {
        width: min-content;
        max-width: 126px;
    }

    .chat .card {
        flex-direction: column;
        margin-bottom: 14px;
    }
    .login-page {
        form {
            transform: scale(0.9);
        }
        .card-header { 
            padding: 3.5rem;
        img { 
            max-width: 50%
        }
        }
        .card-body { 
        h3 { 
            font-size: 1.25rem;
            margin: 1rem 0!important
        }
        }
    }

    .home-dashboard { 
         .flag-count .count { 
            width: 40px;
            height: 40px;
            font-size: 14px
          }

          .dashboard-card-title { 
            font-size: 14px;
          }

          .flag-count span { 
            font-size: 11px;
            margin-top: 5px
          }

          .card-body { 
            padding: 1rem;
            
          }
    }

    .table-timesheet h2 {
    font-size: 30px;
    }
    
    .table-timesheet h2 + p { 
    font-size: 12px!important;
    }

  }
  
  .hide {
    transform: translateX(-166px);
  }
  
  .expand {
    transform: translateX(0);

    .short-logo { 
        opacity: 0!important;
        transition: all .2s linear!important;
        animation: hideElement .2s linear forwards;
    }

    .nav-link-text, .logo { 
        opacity: 1!important;
        transition: all .2s linear!important;
    }
  }
  
  .menu-toggle-btn {
    position: fixed;
    top: 10px;
    right: -65px;
    z-index: 1000;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
  
  .menu-toggle-btn img {
    display: block;
  }
  
  .menu-toggle-btn:hover img {
    transform: scale(1.1);
    transition: transform 0.3s linear;
  }

#sidenav-collapse-main{ 
    margin: 0 0 0 auto!important;
}

.hide {
    .nav-link-text, .logo { 
        opacity: 0!important;
        transition: all .2s linear!important;
        animation: hideElement .2s linear forwards;
    }

    #sidenav-collapse-main, #sidenav-collapse-main ul * { 
        width: fit-content!important;
    }

    a.nav-link {
        .icon { 
            margin: 0!important
        }
    }

    .navbar-vertical .navbar-nav .nav-link .icon { 
        margin: 0!important;
    }
}


@keyframes hideElement {
    0% { 
        width: auto;
    }
    100% {
        width: 0;
        display: none;
    }
}

.more-options { 
    background: #F7F1ED;
    width: 30px;
    height: 30px;
    border: 0;
    outline: 0;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;

    img { 
        height: 18px;
        object-fit: contain;
    }
}

table {
    th { 
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #847F7B;
    }
    td { 
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #847F7B;
    }
}

.add-new { 
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    padding: 9px 24px;
}

.badge { 
    font-size: 12px!important;
}

h6.table-title { 
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    text-transform: uppercase;

    .project-code, .project-name { 
        font-size: 22px;
    }
}

.btn-tools { 
    display: flex;
    width: 30px;
    height: 30px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background: #F7F1ED;
    border-radius: 4px;
}

.dashboard-card-title { 
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #4F4B48;
}

.search input { 
    width: 354px;
    border: 0!important;
    box-shadow: none!important;
    outline: none!important;
}

.cdk-drop-list::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #F5F5F5; 
  }
  
  
  .item-drop { 
    cursor: pointer!important
  }
  
  ul li:last-child { 
  border: none!important
  }
  
  .ng-select-single .ng-select-container.ng-has-value { 
    .ng-value { 
      color: #000!important;
    }
  }


  .btn-options { 
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    background: #F7F1ED;
    padding: 0;
    border-radius: 4px!important;
    box-shadow: none;
    &.open { 
        background: #2D2926;
    }
  }

  ul.options-box { 
    display: flex;
    padding: 0;
    align-items: start;
    background: #2D2926;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 50%;
    transform: translate(50%, -16%);
    list-style: none;
    z-index: 2;
    
    li { 
        color: #fff;
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 12px 36px 12px 12px;
        font-size: 12px;

        &:hover { 
            background: #4F4B48;
            cursor: pointer;
        }

        .option-icon { 
            width: 40px;
        }

        img {
            margin-right: 8px;
        }
    }
  }

.table-responsive { 
    overflow: inherit!important;
    * { 
        table, tr, td { 
            overflow: inherit!important;
        }
    }
}